import { Box } from '@mui/material';
import HeaderUserbox from './HeaderUserbox';

function Header() {
    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            <Box
                sx={{
                    minHeight: '64px',
                    width: '100%',
                    backgroundColor: '#fff',
                    position: 'sticky',
                    top: 0,
                    zIndex: 100
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%'
                    }}
                >
                    <HeaderUserbox />
                </Box>
            </Box>
        </Box>
    );
}

export default Header;
