import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import SidebarView from 'src/components/layout/SidebarView';
import { useAuth } from 'src/contexts/AuthContext';
import Header from './components/Header';

interface SidebarLayoutProps {
    children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
    const { token } = useAuth();
    if (!token) {
        // If not authenticated, redirect to the login page
        return <Navigate to="/sign-in" />;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                position: 'relative'
            }}
        >
            <SidebarView />
            <Box
                sx={{
                    height: '100%',
                    width: '100%'
                }}
            >
                <Header />

                <Outlet />
            </Box>
        </Box>
    );
};

export default SidebarLayout;
